/**
 *  项目的配置文件
 */
export const chatUrl = 'wss://im.waioushop.com';//客服地址
export const mUrl = ''//移动端网页地址
export const apiUrl = 'https://waioushop.com/';//接口请求地址
// export const apiUrl = 'http://localhost:8001/';//接口请求地址
// export const apiUrl = 'https://waiou.sfifc.vip/';//测试环境地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = '外鸥商城';//浏览器顶部title
export const gdKey = 'bf4fee9b52a1a2710c39df31a01ea61a';//高德web-js key
export const gdSecurityCode = '9d438e8fe5e02284aa206c99d2d90b43'//高德web-js 安全密钥
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v4.1 *** date-2022-07-22 ***主版本v4.1**/



